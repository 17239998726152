import React from "react"
import Layout from "../components/Layout/Layout"
import HeaderImg from "../components/Components/HeaderImg";
import { StaticImage } from "gatsby-plugin-image";

export default function Omne() {
    return (
        <Layout>
            <HeaderImg />
            <div className="container pb-5">
                <h1>O mně</h1>
                <p>Jmenuji se Jana Hrstková.</p>
                <p>Aktuálně poskytuji výhradně individuální lekce a terapie s osobním kontaktem, abych se Vám mohla vždy plně věnovat. </p>
                <p>Pohybovým aktivitám, vedení tanečního studia a organizaci sportovních kurzů jsem se věnovala od roku 2014. Narození dvou synů bylo pro mě milníkem v intenzivní práci s vlastním tělem od jeho středu. Předávané znalosti a dovednosti jsem získala mnohaletou praxí a rozšířila na odborných kurzech hlavně pro vlastní potřebu, protože jinde jsem nenacházela odpovědi na své otázky v těhotenství i následném mateřství.</p>
                <p>Důležitou součástí poskytovaných lekcí a terapií se během roku 2021 stala terapie BEMER.</p>

                <div className="text-center mt-5 mx-auto w-50">
                    <StaticImage layout={'fullWidth'} imgClassName={"janahrstkova"} src="../assets/custom-style/images/janahrstkova.jpg" />
                </div>

            </div>

        </Layout>
    );
}